.redirect_to_select_dropdown:global(.ant-select-dropdown .ant-select-item-option-content) {
	white-space: pre-wrap;
}
.redirect_to_select_dropdown:global(.ant-select-dropdown .ant-select-item-group) {
	color: var(--font-color);
	text-transform: uppercase;
}
.option_description {
	font-size: 13px;
}

.delete_btn {
	align-self: flex-end;
}
